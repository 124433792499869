import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BarcodeService } from './barcode.service';

@Component({
  selector: 'app-print-modal',
  templateUrl: './print-modal.component.html',
  styleUrls: ['./print-modal.component.css']
})
export class PrintModalComponent implements OnInit {

  @Input()
  public barcodeValue: string;

  @Input()
  public paddingText: string;

  public image: string | ArrayBuffer;

  constructor(public modal: NgbActiveModal, private barcodeService: BarcodeService) { }

  ngOnInit() {
    this.getImage(this.barcodeValue, this.paddingText);
  }

  public getImage(value: string, paddingText: string) {
    this.barcodeService.getBarcode(value, paddingText).subscribe(x => {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        this.image = reader.result;
      }, false);

      reader.readAsDataURL(x);
    });
  }

  public print() {
    window.print();
  }
}
