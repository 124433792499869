export class User {
  constructor(public username: string, public roles: string[]) { }
}


export class AuthData {
  name: string;
  roles: string[];
  token: string;
}


export class UserRoles {
  static readonly admin = 'admin';
  static readonly wizmo = 'wizmo';
  static readonly customer = 'customer';
}
