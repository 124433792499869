import { Component, OnInit, Input } from '@angular/core';
import { PasswordReset } from '../user-management.models';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public password: PasswordReset;
  public flags = { isSuccess: null, errors: null };
  constructor(private route: ActivatedRoute, private userService: UserManagementService) { }

  ngOnInit() {
    this.password = new PasswordReset();
  }


  public submit() {
    console.log('resetting password', this.password);
    this.flags.isSuccess = null;
    this.flags.errors = null;
    let token = this.route.snapshot.queryParams['token'];
    let userId = this.route.snapshot.queryParams['userId'];
    this.userService.resetPassword(token, userId, this.password.password1)
      .subscribe(ok => {
        this.flags.isSuccess = true;
      },
      (err) => {
        console.log('errs:', err);
          this.flags.errors = err.error;
          console.log('err', this.flags);
        });
  }
}
