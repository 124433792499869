import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { AuthModule } from './auth/auth.module';
import { LoginComponent } from './auth/login/login.component';
import { AuthService } from './auth/auth.service';
import { AuthInterceptor } from './auth/auth-interceptor';
import { PrintModalComponent } from './print-modal/print-modal.component';
import { UserManagerComponent } from './admin/user-management/user-manager/user-manager.component';
import { ResetPasswordComponent } from './admin/user-management/reset-password/reset-password.component';
import { UserManagementModule } from './admin/user-management/user-management.module';
import { ForgotPasswordComponent } from './admin/user-management/forgot-password/forgot-password.component';
import { IsLoggedInGuard } from './auth/guards/is-logged-in.guard';
import { IsAdminGuard } from './auth/guards/is-admin.guard';
import { AdminContainerComponent } from './admin/admin-container/admin-container.component';
import { AdminModule } from './admin/admin.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { IsInRoleGuard } from './auth/guards/is-in-role.guard';
import { CsvComponent } from './csv-upload/csv.component';
import { StoreComponent } from './store/store.component';



@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    PrintModalComponent,
    CsvComponent,
    StoreComponent,
  ],
  entryComponents: [
    PrintModalComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    NgbModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'password-reset', component: ResetPasswordComponent },
      { path: 'password-restore', component: ForgotPasswordComponent },
      { path: 'csv', component: CsvComponent, canActivate: [IsLoggedInGuard] },
      { path: 'store', component: StoreComponent, canActivate: [IsLoggedInGuard] },

      {
        path: 'admin', component: AdminContainerComponent, canActivate: [IsAdminGuard],
        children: [
          { path: 'users', component: UserManagerComponent, canActivate: [IsLoggedInGuard, IsAdminGuard] },
        ]
      },
    ]),
    AuthModule,
    UserManagementModule,
    AdminModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private auth: AuthService) {
  }
}
