import { Component, OnInit } from '@angular/core';
import { UserInfo } from '../user-management.models';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.css']
})
export class UserManagerComponent implements OnInit {
  public addedUser: UserInfo;
  constructor() { }

  ngOnInit() {
  }


  onUserCreated(user: UserInfo) {
    console.log("Created new user", user);
    this.addedUser = user;
  }
}
