import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StoreModel } from './store-model';
import { StoreService } from './store.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-csvModels',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  public storeModel: StoreModel[] = [];
  public currentStoreModel = new StoreModel();
  public message: string;
  constructor(private StoreService: StoreService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.StoreService.getStores().subscribe(x => this.storeModel = x);
    this.registerForm = this.formBuilder.group({
      id: [''],
      storeName: ['', Validators.required],
      storeType: ['', Validators.required],
      addressLineOne: ['', [Validators.required, Validators.maxLength(30)]],
      addressLineTwo: ['', [Validators.maxLength(30)]],
      city: ['', [Validators.required, Validators.maxLength(30)]],
      province: ['', [Validators.required, Validators.maxLength(20)]],
      postal: ['', [Validators.required, Validators.maxLength(6)]],
      contactPhoneNumber: ['', Validators.required],
      contactEmail: ['', [Validators.required, Validators.email]],
      aliases: [''],
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.copyFromForm(this.registerForm);
    this.addStore();
  }

  public addStore() {
    this.StoreService.createStore(this.currentStoreModel).subscribe(x => {
      this.storeModel.push(x);
      this.currentStoreModel = new StoreModel();
    });
  }

  public prepStoreForUpdate(store: StoreModel) {
    let store2 = StoreModel.clone(store);
    this.currentStoreModel = store2;
    this.copyToForm(this.currentStoreModel);
  }

  public updateCsvModel() {
    this.StoreService.updateStore(this.currentStoreModel).subscribe(() => {
      let idx = this.storeModel.findIndex(x => x.id === this.currentStoreModel.id);
      this.storeModel.splice(idx, 1, this.currentStoreModel);
      this.currentStoreModel = new StoreModel();
    });
  }

  public copyFromForm(registerFormInput: FormGroup) {
    this.currentStoreModel.id = registerFormInput.value.id;
    this.currentStoreModel.addressLineOne = registerFormInput.value.addressLineOne;
    this.currentStoreModel.addressLineTwo = registerFormInput.value.addressLineTwo;
    this.currentStoreModel.city = registerFormInput.value.city;
    this.currentStoreModel.storeName = registerFormInput.value.storeName;
    this.currentStoreModel.storeType = registerFormInput.value.storeType;
    this.currentStoreModel.contactEmail = registerFormInput.value.contactEmail;
    this.currentStoreModel.contactPhoneNumber = registerFormInput.value.contactPhoneNumber;
    this.currentStoreModel.province = registerFormInput.value.province;
    this.currentStoreModel.postal = registerFormInput.value.postal;
    this.currentStoreModel.aliases = registerFormInput.value.aliases;    
  };

  public copyToForm(store: StoreModel) {
    this.registerForm.setValue({
      id: store.id, 
      addressLineOne: store.addressLineOne, 
      addressLineTwo: store.addressLineTwo,
      city: store.city,
      storeName: store.storeName,
      storeType: store.storeType,
      contactEmail:store.contactEmail,
      contactPhoneNumber:store.contactPhoneNumber,
      province: store.province,
      postal: store.postal,
      aliases: store.aliases
    });    
  };

}
