import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CsvModel } from './csv-models';

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  private serviceApi = '/api/csv/';

  constructor(private http: HttpClient) { }

  public createCsvModel(csvModel: CsvModel) {
    return this.http.post<CsvModel>(this.serviceApi, csvModel);
  }

  public uploadCsv(csvModel: FormData) {
    
    // return this.http.post(this.serviceApi+'upload', csvModel, {reportProgress: true, observe: 'events'} );
    return this.http.post(this.serviceApi+'upload', csvModel);

    
  }

  public getCsvModels() {
    return this.http.get<CsvModel[]>(this.serviceApi);
  }

  public updateCsvModel(csvModel: CsvModel) {
    return this.http.put<void>(this.serviceApi + csvModel.file, csvModel);
  }
}
