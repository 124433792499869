import { Component, OnInit, EventEmitter, Output, } from '@angular/core';
import { UserInfo } from '../user-management.models';
import { UserManagementService } from '../user-management.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  public user: UserInfo;
  public flags = { isSuccess: null, error: null };
  @Output()
  public userCreated = new EventEmitter<UserInfo>();

  constructor(private usersService: UserManagementService) { }

  ngOnInit() {
    this.user = new UserInfo();
  }

  public save() {
    this.flags.isSuccess = null;
    this.flags.error = null;
    this.usersService.create(this.user).subscribe(x => {
      this.user = new UserInfo();
      this.userCreated.emit(x);
      this.flags.isSuccess = true;
    },
      err => {
        this.flags.error = err.error;
      });
  }

}
