export class CsvModel {
    public id: number;
    public file: string;  
  
  
    static clone(csv: CsvModel): CsvModel {
      let csv2 = new CsvModel();
      csv2.file = csv.file;
      csv.id = csv.id
      return csv2;
    }
  }
  