import { Component, OnInit } from '@angular/core';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public username: string;
  public flags = {
    isSuccess: null,
    invalidUsername: null
  };

  constructor(private userService: UserManagementService) { }

  ngOnInit() {
  }

  public submit() {
    this.flags.invalidUsername = null;
    this.flags.isSuccess = null;
    if (this.username) {
      this.userService.sendPasswordRestoreEmail(this.username)
        .subscribe(ok => {
          this.flags.isSuccess = true;
        }, err => {
          this.flags.invalidUsername = true;
        })
    }
  }
}
