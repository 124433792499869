import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCreateComponent } from './user-create/user-create.component';
import { UsersListComponent } from './users-list/users-list.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserManagerComponent } from './user-manager/user-manager.component';
import { FormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
  declarations: [UserCreateComponent, UsersListComponent, ResetPasswordComponent, UserManagerComponent, ForgotPasswordComponent],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class UserManagementModule { }
