import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, AuthData, UserRoles } from './auth.models';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl = '/api/auth/';

  private _currentUser: User;
  public get currentUser() {
    return this._currentUser;
  }

  private _token: string;
  public get token() {
    return this._token;
  }

  public get isLoggedIn() {
    return !!this._currentUser;
  }

  constructor(private http: HttpClient, private router: Router) {
    let storedAuth = localStorage.getItem('auth');
    if (!!storedAuth) {
      let auth: AuthData = JSON.parse(storedAuth);

      this._currentUser = new User(auth.name, auth.roles);
      this._token = auth.token;
    }
  }


  public login(username: string, password: string) {
    let url = this.apiUrl + 'login';
    let req = { username: username, password: password };
    return this.http.post<AuthData>(url, req).pipe(map(resp => {
      this._currentUser = new User(resp.name, resp.roles);
      this._token = resp.token;

      localStorage.setItem('auth', JSON.stringify(resp));

      return resp;
    }));
  }

  public clearAuthData() {
    localStorage.removeItem('auth');
    this._currentUser = null;
    this._token = null;
  }

  public logout() {
    this.clearAuthData();
    this.router.navigate(['']);
  }
}
