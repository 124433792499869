import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StoreModel } from './store-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private serviceApi = '/api/store/';

  constructor(private http: HttpClient) { }

  public createStore(storeModel: StoreModel) {
    if (!!storeModel.id) {
      return this.http.put<StoreModel>(this.serviceApi, storeModel).pipe(map(x => this.enrichStore(x)));
    }
    return this.http.post<StoreModel>(this.serviceApi, storeModel).pipe(map(x => this.enrichStore(x)));
  }

  public getStores() {
    return this.http.get<StoreModel[]>(this.serviceApi);
  }

  public updateStore(storeModel: StoreModel) {
    return this.http.put<void>(this.serviceApi + storeModel.id, storeModel);
  }

  private enrichStore(storeData: StoreModel) {
    let s = new StoreModel();
    Object.assign(s, storeData);
    return s;
  }
}
