import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserInfo } from '../user-management.models';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {

  public users: UserInfo[];

  @Input()
  public addedUser: UserInfo = null;

  constructor(private usersService: UserManagementService, private modalService: NgbModal) { }

  ngOnInit() {
    this.loadUsers();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadUsers();
    console.log('loaded users');
  }

  public delete(modalContent, user: UserInfo) {
    this.modalService.open(modalContent).result.then((result) => {
      this.usersService.deleteUser(user).subscribe(x => {
        let idx = this.users.findIndex(x => x.id == user.id);
        this.users.splice(idx, 1);
      });
    }, (reason) => {
      console.log(reason);
    });

  }

  public create(userName: string, email: string, role: string) {
    let user = new UserInfo();
    user.userName = userName;
    user.email = email;
    user.role = role;
    this.usersService.create(user).subscribe(x => {
      this.users.push(x);
    });
  }

  public loadUsers() {
    this.usersService.getAllUsers().subscribe(x => { this.users = x });
  }

}
