import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { CsvModel } from './csv-models';
import { CsvService } from './csv.service';

@Component({
  selector: 'app-csvModels',
  templateUrl: './csv.component.html',
  styleUrls: ['./csv.component.css']
})
export class CsvComponent implements OnInit {
  public csvModels: CsvModel[] = [];
  public currentCsvModel = new CsvModel();
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();
  constructor(private CsvService: CsvService, private http: HttpClient) { }

  ngOnInit() {
    // this.CsvService.getCsvModels().subscribe(x => this.csvModels = x);
  }


  public addCsvModel() {
    this.CsvService.createCsvModel(this.currentCsvModel).subscribe(x => {
      this.csvModels.push(x);
      this.currentCsvModel = new CsvModel();
    });
  }


  public uploadCsv(files: FileList){   
  
      if(files && files.length > 0) {
        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('filesData', fileToUpload, fileToUpload.name);
        this.message = 'Please wait...';  
        this.CsvService.uploadCsv(formData).subscribe(
          (result) => {
            // new or updated bag here
            this.progress = 100;
            this.message = 'Upload success.';  
          },
          (err) => {
            this.message = 'Error Uploading Document: ' + err.error;  
          }
        );
      }   
  }

  public updateCsvModel() {
    this.CsvService.updateCsvModel(this.currentCsvModel).subscribe(() => {
      let idx = this.csvModels.findIndex(x => x.id === this.currentCsvModel.id);
      this.csvModels.splice(idx, 1, this.currentCsvModel);
      this.currentCsvModel = new CsvModel();
    });
  }
}
