export class StoreModel {
  public id: number;
  public storeName: string;
  public storeType: string;
  public addressLineOne: string;
  public addressLineTwo: string;
  public city: string;
  public province: string;
  public postal: string;
  public aliases: string;
  // public countryCode: string;  
  public contactPhoneNumber: string;
  public contactEmail: string;

  static clone(store: StoreModel): StoreModel {
    let store2 = new StoreModel();
    store2.id = store.id;
    store2.storeName = store.storeName;
    store2.storeType = store.storeType;
    store2.addressLineOne = store.addressLineOne;
    store2.addressLineTwo = store.addressLineTwo;
    store2.city = store.city;
    store2.province = store.province;
    store2.postal = store.postal;
    store2.aliases = store.aliases;
    // store2.countryCode = store.countryCode;
    store2.contactPhoneNumber = store.contactPhoneNumber;
    store2.contactEmail = store.contactEmail
    return store2;
  }
}
